/*
* URL分为3中类型
* 1. 以uni://开头的地址是uni-app原生的页面
* 2. 以http或者https开头的地址是H5
* 3. 跳转到其它APP的地址
* */
import Router from '../router/index'

/**
 *
 * @param url string
 * @param type string
 * @param params object
 * @returns {Promise<void>}
 * @constructor
 */
export const Jump = async function (url, params, type = 'navigate') {
  if (!url) return
  const index = url.indexOf(':')
  if (index !== -1) {
    const schema = url.substring(0, index)
    let targetPath
    switch (schema) {
      case 'uni':
        const CacheUrl = url.substring(index + 1)
        const idx = CacheUrl.lastIndexOf('/')
        targetPath = CacheUrl.substring(idx)

        console.log(targetPath, type, '跳转的地址')
        switch (type) {
          case 'redirect':
            await Router.replace(targetPath)
            break
          default:
            await Router.push(targetPath)
            break
        }

        break
      case 'http':
      case 'https':
        window.location.href = url
        break
      default:
        console.log('跳转到其它APP 或者 小程序 或者其它的内容')
        break
    }
  } else {
    switch (type) {
      case 'redirect':
        await Router.replace(url)
        break
      default:
        const ob = { path: url }
        if (params) {
          ob.query = { ...params }
        }
        await Router.push({ ...ob })
        break
    }
  }
}

export default { Jump }
